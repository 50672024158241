import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-card", {
    staticStyle: {
      "overflow-x": "scroll"
    },
    attrs: {
      bordered: false
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.createForm
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "合集名称："
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["collection_name", {
        rules: [{
          required: true,
          message: "请填写合集名称"
        }]
      }],
      expression: "[\n                        'collection_name',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请填写合集名称',\n                                },\n                            ],\n                        },\n                    ]"
    }],
    attrs: {
      placeholder: ""
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "Slug："
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["collection_slug", {
        rules: [{
          required: _vm.checkNick,
          message: "请填写合集名称"
        }]
      }],
      expression: "[\n                        'collection_slug',\n                        {\n                            rules: [\n                                {\n                                    required: checkNick,\n                                    message: '请填写合集名称',\n                                },\n                            ],\n                        },\n                    ]"
    }],
    attrs: {
      disabled: _vm.disabled,
      placeholder: ""
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "合约地址："
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["contract_address", {
        rules: [{
          required: true,
          message: "请填写合约地址"
        }]
      }],
      expression: "[\n                        'contract_address',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请填写合约地址',\n                                },\n                            ],\n                        },\n                    ]"
    }],
    attrs: {
      disabled: _vm.disabled,
      placeholder: ""
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "来源："
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["source", {
        rules: [{
          required: true,
          message: "请选择来源"
        }]
      }],
      expression: "[\n                        'source',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请选择来源',\n                                },\n                            ],\n                        },\n                    ]"
    }],
    staticStyle: {
      width: "262px"
    },
    attrs: {
      placeholder: "请选择",
      disabled: _vm.disabled,
      size: "large"
    },
    on: {
      change: _vm.sourceChange
    }
  }, _vm._l(_vm.sourceList, function (item) {
    return _c("a-select-option", {
      key: item,
      attrs: {
        value: item
      }
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 1)], 1), _c("a-form-item", {
    attrs: {
      label: "是否计算稀有度："
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["is_fixed_nft_quantity", {
        rules: [{
          required: true,
          message: "请填写模块名称"
        }]
      }],
      expression: "[\n                        'is_fixed_nft_quantity',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请填写模块名称',\n                                },\n                            ],\n                        },\n                    ]"
    }],
    staticStyle: {
      width: "262px"
    },
    attrs: {
      placeholder: "请选择",
      size: "large"
    }
  }, _vm._l(_vm.isfixedList, function (item) {
    return _c("a-select-option", {
      key: item.id
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _c("a-form-item", {
    attrs: {
      label: "所属分类："
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["category_id", {
        rules: [{
          required: true,
          message: "请选择分类"
        }]
      }],
      expression: "[\n                        'category_id',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请选择分类',\n                                },\n                            ],\n                        },\n                    ]"
    }],
    staticStyle: {
      width: "262px"
    },
    attrs: {
      placeholder: "请选择",
      size: "large"
    }
  }, _vm._l(_vm.categoriesdata, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1)], 1), _c("div", {
    staticClass: "footer"
  }, [_c("a-button", {
    staticClass: "btn_l",
    attrs: {
      size: "large"
    },
    on: {
      click: function click($event) {
        return _vm.handleAdd(1);
      }
    }
  }, [_vm._v("取消")]), _c("a-button", {
    attrs: {
      size: "large",
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.handleAdd();
      }
    }
  }, [_vm._v(_vm._s(_vm.editId ? "保存" : "创建"))])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };