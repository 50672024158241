import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.push.js";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { DcListApi } from "@/api";
export default {
  data: function data() {
    return {
      createForm: this.$form.createForm(this, {
        name: "page_home"
      }),
      isfixedList: [{
        id: 1,
        name: "是",
        value: true
      }, {
        id: 0,
        name: "否",
        value: false
      }],
      sourceList: ["OPENSEA", "KNOWHERE"],
      parentcategoryid: "",
      categoryid: "",
      checkNick: true,
      collection_id: ''
    };
  },
  watch: {},
  computed: _objectSpread(_objectSpread({}, mapState("datadc", ["categoriesdata"])), {}, {
    editId: function editId() {
      return this.$route.query.id;
    },
    disabled: function disabled() {
      if (this.$route.query.id) return true;
    }
  }),
  mounted: function mounted() {
    // console.log(this.$route);
    this.getCategories();
    // const params=this.$route.params
    if (this.editId) {
      this.getDetail();
    }
  },
  methods: _objectSpread(_objectSpread({}, mapActions("datadc", ["getCategories"])), {}, {
    getDetail: function getDetail() {
      var _this = this;
      DcListApi.collectDetail({
        contract_id: this.editId
      }).then(function (res) {
        console.log(res);
        _this.collection_id = res.id;
        var name = res.name,
          slug = res.slug,
          contract_address = res.contract_address,
          source = res.source,
          is_fixed_nft_quantity = res.is_fixed_nft_quantity,
          category_id = res.category_id;
        _this.$nextTick(function () {
          _this.createForm.setFieldsValue({
            collection_name: name,
            collection_slug: slug,
            contract_address: contract_address,
            source: source,
            is_fixed_nft_quantity: is_fixed_nft_quantity == true ? 1 : 0,
            category_id: category_id
          });
        });
      });
    },
    handleAdd: function handleAdd(val) {
      var _this2 = this;
      if (val) {
        // this.createForm.resetFields();
        this.$router.push("/dcdata/index");
        return;
      }
      this.createForm.validateFields(function (err, value) {
        console.log(value, "value");
        if (!err) {
          var params = _objectSpread(_objectSpread({}, value), {}, {
            is_fixed_nft_quantity: value.is_fixed_nft_quantity == 1 ? true : false
          });
          // console.log(params);
          if (!_this2.editId) {
            DcListApi.saveCollection(params).then(function (res) {
              if (res) {
                _this2.toast("新增成功", "success");
                _this2.$router.push("/dcdata/index");
              }
            });
          } else {
            params = _objectSpread(_objectSpread({}, params), {}, {
              collection_id: _this2.collection_id
            });
            DcListApi.updateCollection(params).then(function (res) {
              if (res) {
                _this2.toast("修改成功", "success");
                _this2.$router.push("/dcdata/index");
              }
            });
          }
        } else {
          console.log(err);
          if (type == 3) {
            _this2.toast("请选择二级类目", "error");
          }
        }
      });
    },
    sourceChange: function sourceChange(e) {
      console.log(e);
      if (e == "KNOWHERE") {
        this.checkNick = false;
        this.createForm.setFieldsValue({
          is_fixed_nft_quantity: 0
        });
      }
    },
    filterOption: function filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }
  })
};